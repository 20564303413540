"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const game_1 = require("@games/tdeecalculator/src/game");
const appModule = ng.module('app');
appModule.component('gameBmrcalculator', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: game_1.TdeecalculatorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('bmrcalculator/');
    }]);
